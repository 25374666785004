export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0,2,3,4];

export const dictionary = {
		"/(protected)": [5,[2]],
		"/(protected)/dashboard/customers": [~6,[2]],
		"/(protected)/dashboard/customers/[id]": [~7,[2,3]],
		"/(protected)/dashboard/customers/[id]/invoices": [~8,[2,3]],
		"/(protected)/dashboard/expenses": [~9,[2]],
		"/(protected)/dashboard/inventories": [~10,[2]],
		"/(protected)/dashboard/inventories/[id]": [~11,[2]],
		"/(protected)/dashboard/invoices": [~12,[2]],
		"/(protected)/dashboard/invoices/[invoice_id]": [~13],
		"/(protected)/dashboard/suppliers": [~14,[2]],
		"/(protected)/dashboard/suppliers/[id]": [~15,[2,4]],
		"/(protected)/dashboard/suppliers/[id]/invoices": [~16,[2,4]],
		"/(protected)/dashboard/transactions": [~17,[2]],
		"/(protected)/dashboard/users": [~18,[2]],
		"/login": [23],
		"/(protected)/pdf-invoice": [~20,[2]],
		"/(protected)/pdf": [~19,[2]],
		"/(protected)/scanner": [21,[2]],
		"/(protected)/settings": [22,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';